var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base_textarea_wrapper",style:(_vm.cssVars)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"mb-1"},[_c('strong',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"base_textarea_content",class:{
            'base_textarea_outlined' : _vm.outlined,
            'base_textarea_slim' : _vm.slim,
            'grey_bg_color' : _vm.dark,
            'white_bg_color' : !_vm.dark,
        }},[(_vm.icon)?_c('div',{staticClass:"base_textarea_icon ml-3 pl-3"},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e(),_vm._t("content"),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],class:{
                'resizeable' : _vm.resize
            },attrs:{"type":_vm.type,"placeholder":_vm.placeholder},domProps:{"value":(_vm.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}}),_c('div',{staticClass:"base_textarea_error_wrapper",class:{
                'base_textarea_error_wrapper_visible' : !!_vm.errorMessage 
            }},[_c('small',[_c('strong',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }